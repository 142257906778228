.imageSizing {
    border:solid 1px gray;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.322);
    width:10rem;
    height:7rem;
   
    /* margin-top:1rem;
    margin-left:10rem; */
    /* margin-left:5rem; */
}