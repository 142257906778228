.navbar1{
    /* width:100vw;
    height:5rem; */
    position:fixed;
    margin-left:-3rem;
    transition:width 3000ms ease;
    
  }
  .navbar-nav1 {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    
  }
  
  .nav-item {
    width: 70%;
  }
  
  
  .nav-link-main {
    display: flex;
    align-items: center;
    height: 5rem;
    color: #ddd8d8;
    text-decoration: none;
    /* filter property so colors are differnet when not hovered */
    filter: grayscale(100%) opacity(0.9);
    transition:width 3000ms ease;
  }
  
  .nav-link-main:hover {
    filter: grayscale(0%) opacity(1);
    /* background: var(--bg-secondary);
    color: var(--text-secondary); */
  }
  
  .link-text {
    display: none;
    margin-left: 1rem;
    margin-top:1.5rem;
  }
  
  .nav-link-main svg {
    width: 2rem;
    min-width: 2rem;
    margin: 0 1.5rem;
  }
  
  .fa-primary {
    color: #b67eff;
  }
  
  .fa-secondary {
    color: #df49a6;
  }
  
  .fa-primary,
  .fa-secondary {
    transition:width 3000ms ease;
  }
  
  .logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    /* color: var(--text-secondary);
    background: var(--bg-secondary); */
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
  }
 
  .logo svg {
    transform: rotate(0deg);
    transition:width 3000ms ease;
  }
  
  .logo-text
  {
    display: inline;
    position: absolute;
    left: -999px;
    /* transition: var(--transition-speed); */
  }
  
  .navbar1:hover .logo svg {
    transform: rotate(-180deg);
  }
  
  /* Small screens */
  @media only screen and (max-width: 600px) {
    .navbar1 {
      bottom: 0;
      width: 100vw;
      height: 5rem;
    }
  
    .logo {
      display: none;
    }
  
    .navbar-nav {
      flex-direction: row;
    }
  
    .nav-link-main {
      justify-content: center;
    }
    main {
      margin: 0;
    }
  }
  /* Large screens */
  @media only screen and (min-width: 600px) {
    .navbar1 {
      top: 0;
      width: 5rem;
      height: 100vh;
    }
  
    .navbar1:hover {
      width: 16rem;
    }
  
    .navbar1:hover .link-text {
      display: inline;
    }
  
    .navbar1:hover .logo svg
    {
      margin-left: 11rem;
    }
  
    .navbar1:hover .logo-text
    {
      left: 0px;
    }
  }
  .theme-icon {
    display: none;
  }
  
  .dark #darkIcon {
    display: block;
  }
  
  .light #lightIcon {
    display: block;
  }
  
  .solar #solarIcon {
    display: block;
  }