:root {
  --text-color: rgb(82, 6, 56);
  --bckgrd: rgb(235, 232, 232);
}
html body {
  height: 100vh;
  margin: 0;
  box-sizing: border-box;
}
.contentAbout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background: var(--bckgrd);
  height:100vh;
}
.aboutHeading {
    display: flex;
    flex-direction: column;
    height:30rem;
    width:100%;
    background-image: url("../assets/images/sunglasses2.jpg");
    /* https://www.neovisioneyecenters.com/the-science-of-sunglasses/ */
    background-color: rgb(109, 10, 56);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center bottom 1em;
    background-attachment:fixed;
    color: white;
    font-size:3rem;
    text-align:start;
    padding-top: 224px;
    padding-left:1rem;
    background-color: lightgray;
  }

.mainAbout {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  flex: 1;
}
.imageContainer{
  display: flex;
  align-items: center;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  margin:1rem 2rem;
  background-color: lightgray;
}
.card1 img{
  margin-left:4rem;
  margin-bottom: 1rem;
}

/* Add rounded corners to the top left and the top right corner of the image */
img {
  border:solid 3px black;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.322);
  width:25rem;
  margin-top:2rem;
  
}

.imageHeading{
  text-align:center;
}
.imageDesc{
  text-align:center;
  
}
.column1{
  text-align: center;
  padding:1rem 1.5rem .0 1.5rem;
}
.column2{
  text-align: center;
  margin:.75rem 2rem .5rem 1.5rem;
}
.resumeLink{
  color:maroon;
  padding-left:2px;
}

.resumeLink:hover{
  font-size:1.15rem;
}
@media(max-width:630px){

  img{
    width:150px;
  }
 
}