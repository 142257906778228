
html{
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Gotu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* this is from the src file because it is referenced in js file */
/* body {
  background-image: url("nightskyBackground.png");
  
} */
body::-webkit-scrollbar{
  width:0.25rem;
}
body::-webkit-scrollbar-track{
  background: rgb(53, 51, 51);
}
body::-webkit-scrollbar-thumb{
  background: rgb(68, 4, 39);
}
