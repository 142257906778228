html body {
  height: 100vh;
  margin: 0;
  box-sizing: border-box;
}
.funHeading {
  display: flex;
  flex-direction: column;
  height:30rem;
  max-width:100%;
  background-image: url("../assets/images/spaceship-background.jpg");
  background-color: rgb(109, 10, 56);
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center  bottom 1em;
  background-attachment:fixed;
  color:linen;
  font-size:3rem;
  text-align:start;
  padding-top: 10rem;
  padding-left:1rem;
  background-color: lightgray;
  animation-name: projectTitle;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}
h3{
  padding-top:1rem;
}
.contentFun {
  display: flex;
  flex-direction: row;
  
}
.mainFun {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  flex: 1;
  background-color: lightgray;
  padding-left:4rem ;
  padding-right:4rem;
  text-align: start;
}
.sectionOne {
  text-align: center;
}
/* .columnFun {
  
} */
.columnFun2 {
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  flex: 1;
}
/* .headingAnimate {
  animation-name: projectTitle;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
} */
@keyframes projectTitle {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

.imageSizing {
    border:solid 1px gray;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.322);
    width:15rem;
    height:7rem;
    margin-top:1rem;
    margin-left:10rem;
    /* margin-left:5rem; */
  }
.moveImage{
  margin-left:7rem;
}
  .additionalItems{
    text-align:right;
    /* padding-right:2rem; */
  }
  .content_items{
    padding: 10px 20px 10px 20px;
  }
  .fun_item{
    padding-bottom:1rem;
    font-weight: bold;
    font-size:1rem;
  }

  ul li{
    text-align:left;
    padding-bottom:1rem;
    
  }
  .list_heading{
    text-decoration: underline;
    font-weight:bold;
    color:maroon;
    

  }
  .list_items{
    margin-right:2rem;
    margin-left:2rem;
    list-style-image:url("https://www.gifs.cc/bullets/red-star-1.png");
  }
  .midImage{
    margin-right:5rem;
  }
  .gifStyle a{
   
    color:white;
  }
  .gifStyle{
    background-color: lightgray;
    text-align: right;
  }

  @media(min-width:1300px){
    .moveImage{
      margin-left:14rem;
  }
}
@media(max-width:620px){
  .contentFun{
    flex-direction: column;
  }
  .moveImage{
    margin-left:0;
  }
}
  /* <a href="https://www.freepik.com/free-photos-vectors/water">Water vector created by brgfx - www.freepik.com</a> */
  /* https://www.wallpapermobi.com/category/39/space-wallpapers/5271/spaceship-background */