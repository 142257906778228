:root {
  --text-color: rgb(82, 6, 56);
}
*{
  box-sizing: border-box;
}
.content {
  display: flex;
  background: lightgray;
  height: 70vh;
}
.welcome {
  height: 5rem;
  background: linen;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 3rem;
  color: rgb(63, 37, 37);
  border-radius: 5px;
  animation-name: grow;
  animation-duration: 2.25s;
  animation-timing-function: ease-in;
}
@keyframes grow {
  from {
    font-size: 2px;
  }
  to {
    font-size: 3.2rem;
  }
}
.journeyBckgrd {
  background-color: lightgray;
}

main {
  flex: 3;
}
article {
  background: linen;
  padding: 2rem;
  margin-top: 5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  /* height: 30rem; */
  height: 60%vh;
  border-radius: 5px;
}
.main-content {
  line-height: 2;
  font-weight: 600;
  font-size: 1.2rem;
}
.quote {
  font-style: italic;
}
.welcomeLine {
  text-align: center;
  padding-top: 0.5rem;
}
.sidebar {
  flex: 1;
  background-color: rgb(243, 243, 195);
  margin: 5rem 1rem;
  margin-right: 3rem;
  color: var(--text-color);
  height: 30rem;
  border-radius: 5px;
}
@media (max-width: 630px) {
  .welcome {
    font-size: 2rem;
  }
  .sidebar{
    margin: 0 auto;
  }
  .content{
    flex-direction: column;
  }
}
