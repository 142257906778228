
.footerDiv {
  display:flex;
  background: linen;
  width:100%;
  /* min-height: 100vh; */
}
.footerStyle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 5rem;
  background: linen;
  list-style: none;
  padding-top: 1.5rem;
  padding-right:4.5rem;
  border-radius: 5px;
}
a {
  padding-left: 4rem;
}
.nav2_link{
  color:blue;
  text-decoration: none;
  transition:color 1.0s, font-size 1.0s;
}
.nav2_link:hover{
  font-size:1.25rem;
  color:maroon;
}
.spacer{
  flex:1;
}

.copyWrite{
  margin-top:1rem;  
  margin-right:1rem;
}
@media (max-width: 980px) {
.footerDiv{
  display: none;
}

}