*{
  box-sizing: border-box;
}
html body {
  height: 100vh;
  min-height:100%;
  margin: 0;
  box-sizing: border-box;
  background-color: lightgray;
}
.main_background{
  background-color: lightgray;
}
.projectsHeading {
  display: flex;
  flex-direction: column;
  height: 30rem;
  width: 100%;
  background-image: url("../assets/images/longRoad.jpg");
  background-color: rgb(109, 10, 56);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom 1em;
  /* background-position:center ; */
  background-attachment: fixed;
  color: linen;
  font-size: 3rem;
  text-align: start;
  padding-top: 10rem;
  padding-left: 1rem;
  background-color: linen;
}
.titleAnimate {
  animation-name: projectTitle;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}
@keyframes projectTitle {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.contentProjects {
  display: flex;
  flex-direction: row;
  flex:1;
  /* width: 100%; */
}
.mainProject {
  display: flex;
  flex-direction: row;
  /* flex-basis: 75%; */
  flex: 1;
}
.subColumnOne {
  /* text-align: start; */
  /* display: flex; */
  flex-direction: column;
  /* flex-basis: 75%; */
  flex: 1;
}
.subColumnTwo {
  
  flex-direction: column;
  /* flex-basis: 75%; */
  flex: 1;
}

/* .imagePlacement1{
  margin-left:-12rem;
}
.imagePlacement2{
  margin-right:5rem;
} */
.pageDescrip{
  text-align: center;
  font-size: 1.45rem;
  color:maroon;
  font-weight: 500;
  text-shadow:1px 2px gray;
  padding-top:1rem;
}
.subHead{
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
}
.firstColumn{
  padding-left:3rem;
  padding-top:1rem;
}
.secondCol{
  
}
.space{
  padding-left:6rem
}
.rightArrow{
  height:2rem;
  width: 4rem;
  border:none;
  margin-top:0;
  margin-left:2rem;
  box-shadow: none;
 

}
.projectDescription{
  margin-top:2rem;
  padding-left:.75rem;
  text-align: center;
}
@media (max-width: 780px) {
  .mainProject{
    flex-direction: column;
  }
  .contentProjects{
    flex-direction:column ;
  }
  .firstColumn{
    display: none;
  }
  .secondCol{
    display: none;
  }
  .projectDescription{
    display: none;
  }
 
  .projectDescription{
    padding-top:2rem;
  }
  .imagePlacement1{
    padding-left:4rem;
  }
  .imagePlacement2{
    padding-left:4rem;
  }
  .main_background{
    display: flex;
    flex-direction: column;
    background-color: lightgray;
  }

  
  }
  @media(max-width:1268px){
    .space{
      padding-left:1rem;
    }
  }
  @media(min-width:1300px){
    .secondCol{
      padding-left:14rem;
    }
  }