.quoteContainer{
    background-color: linen;
    flex: 1;
    margin: 5rem 1rem;
    margin-right: 2.5rem;
    color: var(--text-color);
    height: 32rem;
    width:20rem;
    border-radius: 5px;
}
.container{
    border: 1px solid black;
    border-radius:15px;
    -webkit-box-shadow: 10px 10px 5px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px -3px rgba(0,0,0,0.75);
    margin-left:1.5rem;
    margin-right:1.1rem;
    padding-top:.85rem;
    padding-bottom:.85rem;
    width:16rem;
    background:  rgb(6, 33, 144);
}

.sidebarTitle{
    font-size: 1.25rem;
    text-align: center;
    margin-top:1rem;
    padding-top:1rem;
}
.quoteBox{
    border:1px solid black;
    height:21rem;
    width:14rem;
    margin-left:.75rem;
    border-radius: 10px;
    -webkit-box-shadow: 10px 10px 5px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px -8px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px -8px rgba(0,0,0,0.75);
    background-color:rgb(128, 0, 0,.75);
    text-align: center;
}

.new_quote{
    border:1px solid black;
    border-radius:5px;
    margin-top:1.5rem;
    margin-left:6rem;
    padding:.5rem;
    background-color:rgb(246, 246, 191);
    font-size:20px;
    box-shadow: 5px 10px 8px -1px rgba(0,0,0,0.75);
}
.new_quote:hover{
    font-weight: bold;
    background-color: rgb(250, 250, 113);
}
.quote{
    padding:.5rem;
    color:white;
    font-size:1rem;
}
.author{
    color:white;
}