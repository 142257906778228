* {
  box-sizing: border-box;
  margin: 0;
}

/* ../ because not directly in component folder but up one to src */
.header {
  width: 100%;
  height: 30rem;
}
.header {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-end;
  background-image: url("../assets/images/nightskyBackground.png");
  background-color: rgb(109, 10, 56);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 10%;
  background-attachment: fixed;
  color: white;
  padding-bottom: 11rem;
  padding-left: 1.5rem;
  font-size: 2rem;
  animation-name: headerTitle;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;

}
@keyframes headerTitle{
  0%{font-size:1px;}
  30%{font-size:1rem;}
  60%{font-size:1.5rem;}
  100%{font-size:2rem;}
}
.header {
  font-family: "Courgette", cursive;
  
}
.headerAddition {
  font-size: 1rem;
}
sub {
  font-size: 0.5rem;
}
/* @media (max-width: 1050px){
  .header{
  align-items: center;
}
} */
